import React, {useEffect, useState} from "react";
import isEmpty from "lodash.isempty";
import './styles.scss';
import TableContainer from "@material-ui/core/TableContainer";
import {Paper} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import axios from "axios";
import OrderItemCell from "../OrderItemCell";
import CircularProgress from "@material-ui/core/CircularProgress";
import {capitalStart} from '../../../../utils/string-manipulation';
import StyledTableCell from './styledComponents/StyledTableCell';
import StyledTableRow from './styledComponents/StyledTableRow';
import Logo from '../../../../components/Logo';

const PackagerGrid = ({
                          headers = [],
                          data = [],
                          getDashboard,
                          noDataComponent = () => <p>No data to show</p>}
) => {

    const [packagers, setPackagers] = useState()
    const [loading, setLoading] = useState(true)


    const getPackagers = async () => {
        setLoading(true)
        try {
            const response = await axios.get(window.RAYAN_API + 'users?roles=packager')
            setPackagers(response.data)
        }catch (e) {
            console.log('error getting users', e)
        }
        setLoading(false)
    }

    useEffect( () => {
        getPackagers()
    }, [])

    if(loading) return <CircularProgress />

    return (
      <TableContainer component={Paper}>
          <Table aria-label="simple table">
              <TableHead>
                  <TableRow>
                      {headers.map( (header, i) => (<TableCell
                        style={{
                            fontWeight: 'bold',
                            borderLeft: header.key !== 'username' && '1px solid #e0e0e0'
                        }}
                        key={i}>
                        {header.key !== 'username' ? header.title : <Logo size={60} />}
                      </TableCell>))}
                  </TableRow>
              </TableHead>
              <TableBody>
                  {data.map((item, i) => {
                      return (
                        <StyledTableRow key={i}>
                            {headers.map( (header, j) =>
                              header.key === 'username'
                                ? <StyledTableCell>{capitalStart(item[header.key])}</StyledTableCell>
                                : <OrderItemCell
                                  orderItem={item[header.key]}
                                  packagers={packagers}
                                  refreshOrderData={getDashboard}
                                  key={j} />
                            )}
                        </StyledTableRow>
                      )

                  })}
              </TableBody>

          </Table>
          {isEmpty(data) && <div style={{ padding: '20px'}}>{noDataComponent()}</div>}
      </TableContainer>
    )
}


export default PackagerGrid
import {useDispatch, useSelector} from "react-redux";
import {hideModal as hideModalAction, showModal as showModalAction} from "./actions";

export const useModal = () => {
    const {name, props, isOpen} = useSelector(state => state.modal);
    const dispatch = useDispatch()

    const showModal = (name, props) => {
        dispatch(showModalAction(name, props))
    }
    const hideModal = () => {
        dispatch(hideModalAction())
    }

    return {
        showModal,
        hideModal,
        isOpen,
        name,
        props
    }
}
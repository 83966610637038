import {useDispatch, useSelector} from "react-redux";
import {showSnack as showSnackAction, hideSnack as hideSnackAction} from "./actions";

export const useSnack = () => {
    const {message, isOpen, severity} = useSelector(state => state.snack);
    const dispatch = useDispatch()

    const showSnack = (message, severity) => {
        dispatch(showSnackAction(message, severity))
    }
    const hideSnack = () => {
        dispatch(hideSnackAction())
    }

    return {
        showSnack,
        hideSnack,
        isOpen,
        message,
        severity
    }
}
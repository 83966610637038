import React, {useEffect, useMemo, useState} from 'react';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useHistory, useParams} from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import {useDialogue} from '../../../../redux/dialogue/hooks';
import {useSnack} from '../../../../redux/snack/hooks';
import {DIALOGUES} from "../../../../redux/dialogue/consts";
import {capitalStart} from "../../../../utils/string-manipulation";
import {MODALS} from "../../../../redux/modal/consts";
import {useModal} from "../../../../redux/modal/hooks";
import { mdiCheck } from '@mdi/js';
import Icon from "@mdi/react";
import {useProducts} from "../../../../redux/products/hooks";

const Order = () => {
  const { id, status, name } = useParams()
  const history = useHistory()
  const { showDialogue } = useDialogue()
  const { showSnack } = useSnack()
  const { showModal } = useModal()

  const [orderItems, setOrderItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [amendMode, setAmendMode] = useState(false)
  const [amendedBasket, setAmendedBasket] = useState({})
  const {getProductNameById, isLoading: isProductsLoading} = useProducts()


  const getOrder = async () => {
    setLoading(true)
    try {
      const response = await axios.get(window.RAYAN_API + `orderitem?orderId=${id}`)
      setOrderItems(response.data)
    }catch (e) {
      showDialogue(DIALOGUES.SIMPLE, { title : e.response?.data.message || 'Error'})
    }
    setLoading(false)
  }


  const openOrderConfirmation = () => {
    showModal(MODALS.CONFIRM_ORDER, {
      basket: amendedBasket,
      onConfirm: () => {
        showDialogue(DIALOGUES.CONFIRMATION, {
          title: `Payment Collected?`,
          confirmLabel: 'Yes',
          cancelLabel: 'No',
          onConfirm: () => {
            completeOrder(true)
          },
          onCancel: () => {
            completeOrder(false)
          }
        })
      }
    })
  }

  const completeOrder = async paymentCollected => {
    let amendedItems
    if(amendMode){
      amendedItems = Object.keys(amendedBasket).reduce((res, productId) => {
        const orderItem = orderItems.find(o => o.productId === productId)
        if(amendedBasket[productId] !== orderItem.quantity){
          res.push({
            id:orderItem._id,
            collectedQuantity: parseInt(amendedBasket[productId])
          })
        }
        return res
      }, [])
    }
    const payload = {
      status: paymentCollected ? 'complete-paid' : 'complete',
      amendedItems,
    }

    try {
      await axios.put(window.RAYAN_API + `orders/${id}/complete`, payload)
      history.push('/operator')
    }catch (e) {
      showSnack(e.response?.data.message || 'Error')
    }
  }

  const openPickupConfirmation = () => {
    showDialogue(DIALOGUES.CONFIRMATION, {
      title: `Do you need to change order?`,
      confirmLabel: 'Yes',
      cancelLabel: 'No',
      onConfirm: () => {
        setAmendMode(true)
        let basket = {}
        orderItems.forEach(o => basket[o.productId] = o.quantity)
        setAmendedBasket(basket)
      },
      onCancel: () => {
        showDialogue(DIALOGUES.CONFIRMATION, {
          title: `Did you collect the payment?`,
          confirmLabel: 'Yes',
          cancelLabel: 'No',
          onConfirm: () => {
            completeOrder(true)
          },
          onCancel: () => {
            completeOrder(false)
          }
        })
      }
    })
  }

  const amendItemQuantity = (newQuantity, productId) => {
    if(newQuantity > 15000) return
    const newAmendedBasket = {
      ...amendedBasket
    }

    newAmendedBasket[productId] = newQuantity
    setAmendedBasket(newAmendedBasket)
  }

  useEffect( () => {
    getOrder()
  }, [])

  if(loading || isProductsLoading) return <CircularProgress />

  return (
      <div>
        <AppBar position="fixed">
          <Toolbar style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Button variant={"contained"} onClick={() => history.goBack()} >Back</Button>
            <p className='order-item__name'>{capitalStart(name)}</p>
          </Toolbar>
        </AppBar>
        <div style={{ paddingBottom: '60px' }}>
          {orderItems.map((item, i) => {
            if (item.quantity === 0) return null
            return (
                <div key={i} className="order-item">
                  <p className='order-item__name'>{getProductNameById(item.productId)}</p>
                  <div className='order-item__right-panel'>
                    {item.status === 'picked' && !amendMode &&
                    <div className='order-item__status'>
                      <Icon path={mdiCheck} size={0.9} color='green' />
                      <p>ready</p>
                    </div>}
                    <p className='order-item__quantity'>{item.quantity}</p>
                    { amendMode
                    &&  <input
                        type="number"
                        min="1"
                        max="9999"
                        inputMode="numeric"
                        value={amendedBasket[item.productId]}
                        onChange={(e) => amendItemQuantity(e.target.value, item.productId)}/>
                    }
                  </div>

                </div>)}
          )}
        </div>
        {status === 'placed' &&
        <AppBar position="fixed" color="primary" style={{
          top: 'auto',
          bottom: 0,
        }}>
          <Toolbar>
            <div className="order-item__button-group">
              <Button
                  variant='contained'
                  color='default'
                  onClick={amendMode ? openOrderConfirmation: openPickupConfirmation}
              >
                {amendMode ? 'Amend Order' : 'Order collected'}
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        }
      </div>
  )
}

export default Order;
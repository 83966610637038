import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { NavLink } from "react-router-dom"
import { logout as logoutAction} from "../../../../redux/auth/actions";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import './styles.scss'
import {DIALOGUES} from "../../../../redux/dialogue/consts";
import {useDialogue} from "../../../../redux/dialogue/hooks";
import Logo from "../../../../components/Logo";

const SideBarMenu = () =>{
    const dispatch = useDispatch()
    const user = useSelector( state => state.auth )
    const isSuperAdmin = user.roles.includes('ROLE_SUPERADMIN')
    const { showDialogue } = useDialogue()
    const logout = () => {
        showDialogue(DIALOGUES.CONFIRMATION, {
            title: 'Are you sure you want to logout',
            onConfirm: () => {
                dispatch(logoutAction())
            }
        })
    }
    return (
        <div className='sidebarmenu' role="presentation">
            <Drawer anchor='left' open={true} variant="persistent">
                <div className='sidebarmenu' role="presentation">
                    <Logo style={{margin: '20px'}}/>
                    <Divider />
                    <NavLink exact to="/">
                        <ListItem button>
                            <ListItemText primary='Dashboard' />
                        </ListItem>
                    </NavLink>
                    <NavLink to="/orders" >
                        <ListItem button>
                            <ListItemText primary='Orders' />
                        </ListItem>
                    </NavLink>
                    <NavLink to="/sales" >
                        <ListItem button>
                            <ListItemText primary='Sales' />
                        </ListItem>
                    </NavLink>
                    <NavLink to="/users" >
                        <ListItem button>
                            <ListItemText primary='Users' />
                        </ListItem>
                    </NavLink>
                    <NavLink to="/operators" >
                        <ListItem button>
                            <ListItemText primary='Operators' />
                        </ListItem>
                    </NavLink>
                    <NavLink to="/packagers" >
                        <ListItem button>
                            <ListItemText primary='Packagers' />
                        </ListItem>
                    </NavLink>
                    <NavLink to="/products" >
                        <ListItem button>
                            <ListItemText primary='Products' />
                        </ListItem>
                    </NavLink>
                    {isSuperAdmin && <NavLink to="/admins" >
                        <ListItem button>
                            <ListItemText primary='Admins' />
                        </ListItem>
                    </NavLink>}

                    <Divider />
                    <ListItem button onClick={logout}>
                        <ListItemText primary='logout' />
                    </ListItem>
                </div>
            </Drawer>
        </div>
    )
}

export default SideBarMenu
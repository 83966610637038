import React from "react";
import isEmpty from "lodash.isempty";
import './styles.scss';
import { withStyles } from '@material-ui/core/styles';
import TableContainer from "@material-ui/core/TableContainer";
import {Paper} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Icon from "@mdi/react";
import {mdiPencil, mdiDelete} from "@mdi/js";

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const DataGrid = ({
                    headers = [],
                    data = [],
                    openEditModal,
                    openDeleteConfirmation,
                    isSuperAdmin,
                    onRowClick = null,
                    noDataComponent = () => <p>No data to show</p>}
) => (
  <TableContainer component={Paper}>
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          {headers.map( (header, i) => (<TableCell style={{ fontWeight: 'bold' }} key={i}>{header.title}</TableCell>))}
          {openEditModal && <TableCell />}
          {openDeleteConfirmation && <TableCell />}

        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, i) => {
          return (
            <StyledTableRow key={i} className={onRowClick && 'data-grid-row'} onClick={() => onRowClick && onRowClick(item)} >
              {headers.map( (header, j) =>
                <TableCell key={j}>{ header.formatter ? header.formatter(item[header.key], i) : item[header.key]}</TableCell>
              )}
              {isSuperAdmin &&
              <TableCell>
                {openEditModal && <span onClick={(e) => {
                  e.stopPropagation();
                  openEditModal(item)
                }}>
                                    <Icon path={mdiPencil}
                                          size={1}
                                          color='#3f51b5'
                                          style={{cursor: 'pointer'}}
                                    />
                                </span>}
                {openDeleteConfirmation && <span onClick={(e) => {
                  e.stopPropagation();
                  openDeleteConfirmation(item)
                }}>
                                    <Icon path={mdiDelete}
                                          size={1}
                                          color='#3f51b5'
                                          style={{cursor: 'pointer', marginLeft: 20}}
                                    />
                                </span>}
              </TableCell>}
            </StyledTableRow>
          )

        })}
      </TableBody>

    </Table>
    {isEmpty(data) && <div style={{ padding: '20px'}}>{noDataComponent()}</div>}
  </TableContainer>
)


export default DataGrid
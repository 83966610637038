export const ACTIONS = {
    SHOW_MODAL:'SHOW_MODAL',
    HIDE_MODAL:'HIDE_MODAL'
}

export const showModal = (name, props) => {
    return {
        type: ACTIONS.SHOW_MODAL,
        payload:{
            name,
            props
        }
    }
}
export const hideModal = () => {
    return {
        type: ACTIONS.HIDE_MODAL
    }
}
import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import './styles.scss'
import axios from "axios";
import useAuth from "../../../../redux/auth/hooks";
import {useDialogue} from "../../../../redux/dialogue/hooks";
import {DIALOGUES} from "../../../../redux/dialogue/consts";
import {useSnack} from "../../../../redux/snack/hooks";
import moment from "moment";

const ORDER_ITEM_STATUS = {
    PICKED: 'picked',
    UNPICKED: 'unpicked'
}

const OrderItemCell = ({orderItem, packagers, refreshOrderData}) => {

    const {id} = useAuth()
    const {showDialogue} = useDialogue()
    const {showSnack} = useSnack()
    const [highlight, setHighlight] = useState(false)
    const hasBeenEdited = orderItem?.packagedQuantity && orderItem.packagedQuantity !== orderItem.quantity
    if(!orderItem || orderItem.quantity === undefined)return <TableCell className="order-item-cell">{orderItem}</TableCell>

    const toggleStatus = async () => {

        if (orderItem.status === ORDER_ITEM_STATUS.UNPICKED || hasBeenEdited) {
            await axios.put(window.RAYAN_API + `orderitem/${orderItem.id}/pick/${id}`)
        } else {
            await axios.put(window.RAYAN_API + `orderitem/${orderItem.id}/unpick/${id}`)
        }
    }

    const handleClick = () => {

        if (orderItem.packagerId && orderItem.packagerId !== id) return null
        setHighlight(true)
        showDialogue(DIALOGUES.CONFIRMATION,{
              title: `Please confirm?`,
              onConfirm: async () => {
                  try {
                      await toggleStatus()
                      showSnack('Update Complete')
                      setHighlight(false)
                      refreshOrderData()
                  }catch (e) {
                      setHighlight(false)
                      showSnack(e.respone?.data.message || 'Unable to pick item', 'error')
                      refreshOrderData()
                  }
              },
              onCancel: () => setHighlight(false),
              onClose: () => setHighlight(false)
          }

        )
    }

    const getBackgroundColor = (orderItem) => {
        const packager = packagers.find(packager => packager.id === orderItem.packagerId);
        if(packager){
            return  packager.color
        }
        return ''
    }

    return(
      <TableCell
        className={`order-item-cell ${highlight ? 'highlight' : ''} ${hasBeenEdited ? 'flash-item-cell' : ''}`}
        style={{
            backgroundColor: getBackgroundColor(orderItem)
        }}
        onClick={handleClick}>
          <p style={{textAlign: 'center', color: hasBeenEdited && 'red',}}>{orderItem.quantity}</p>
          {hasBeenEdited ? <p style={{textAlign: 'center', fontSize: '12px'}}>{orderItem.packagedQuantity}</p> :
          orderItem.packagedTime && <p style={{fontSize:12}}><i>{ moment(orderItem.packagedTime).format('hh:mma')}</i></p>}
      </TableCell>
    )
}
export default OrderItemCell
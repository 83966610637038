import React, {useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import moment from 'moment'
import axios from "axios";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DateSelector from '../../componenets/DateSelector';
import DataGrid from "../../componenets/DataGrid";
import {useOrders} from "../../../../redux/orders/hooks";
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSnack} from "../../../../redux/snack/hooks";
import {capitalStart} from '../../../../utils/string-manipulation';

const Orders = () => {

    const [ loading, setLoading ] = useState(true)
    const [ orders, setOrders ] = useState([])
    const [ users, setUsers ] = useState([])
    const { setUser, setToDate, setFromDate, filters } = useOrders()
    const { showSnack } = useSnack()

    const history = useHistory();
    const getOrders = async () => {
        const from = filters.date.from?.setHours(0,0,0,0)
        const to = filters.date.to?.setHours(23,59,59,999)

        const dateQuery = (from && to) ? `?dateFrom=${from}&dateTo=${to}` : '?'
        const userQuery = filters.user ? `&userId=${filters.user}` : ''

        try {
            const response = await axios.get(window.RAYAN_API + `orders${dateQuery + userQuery}`)
            setOrders(response.data)
        }catch (e) {
            console.log('error getting orders', e)
        }
        setLoading(false)
    }
    const getUsers = async () => {
        try {
            const response = await axios.get(window.RAYAN_API + 'users')
            setUsers([...response.data])
        }catch (e) {
            console.log('error getting users', e)
        }
    }

    useEffect( () => {
        getOrders()
    }, [filters.date, filters.user])

    useEffect( () => {
        getUsers()
    }, [])

    const getAllOrders = () => {
        setToDate(null)
        setFromDate(null)
    }

    const resetFilters = () => {
        setToDate(new Date())
        setFromDate(new Date())
        setUser("")
    }

    const markOrderAsPaid = async orderIndex => {
        try{
            const { _id }  = orders[orderIndex]
            await axios.put(`${window.RAYAN_API}orders/${_id}/paid`)
            await getOrders()
            showSnack('order marked as paid')

        }catch (e) {
            showSnack(e.response?.data.message || 'error', 'error')
        }

        setLoading(false)
    }

    if(loading) return <CircularProgress />


    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                </Toolbar>
            </AppBar>
            <div style={{ display: 'flex', padding: '10px'}}>
                <DateSelector />
                <FormControl>
                    <InputLabel id="filter-user-label">Filter by User</InputLabel>
                    <Select
                        labelId="filter-user-label"
                        id="select-ting"
                        style={{ width: '200px'}}
                        value={filters.user || ''}
                        onChange={(e) => setUser(e.target.value)}
                    >
                        <MenuItem value="">
                            None
                        </MenuItem>
                        {users.map(user => <MenuItem value={user.id}>{user.username}</MenuItem>)}
                    </Select>
                </FormControl>
                <Button variant="outlined" color="primary" style={{ marginLeft: '20px'}} onClick={getAllOrders}>Get All Orders</Button>
                <Button variant="text" color="secondary" style={{ marginLeft: '20px'}} onClick={resetFilters}>Reset</Button>
            </div>
            <DataGrid
                onRowClick={(item) => history.push(`/orders/${item._id}`)}
                data={orders}
                headers={[
                    {title: 'Date',
                        key: 'date',
                        formatter: text => moment(text).format('DD/MM/YYYY HH:mm')
                    },
                    {
                        title: 'Buyer',
                        key: 'username',
                        formatter: text => capitalStart(text)
                    },
                    {
                        title: 'Status',
                        key: 'orderStatus'
                    },
                    {
                        title: '',
                        key: 'orderStatus',
                        formatter: (status, index) => {
                            if(status === 'complete'){
                                return (
                                    <Button variant='contained'
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                markOrderAsPaid(index)
                                            }}>
                                        Mark as paid
                                    </Button>)
                            }

                        }
                    }
                ]}
            />
        </div>
    );
}

export default Orders;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import DataGrid from '../../componenets/DataGrid';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import moment from 'moment';
import CircularProgress from "@material-ui/core/CircularProgress";

const User = () => {
    const { id } = useParams();
    const { goBack } = useHistory();
    const history = useHistory();
    const [user, setUser] = useState({});
    const [orders, setOrders] = useState([]);
    const [ loadingUser, setLoadingUser ] = useState(true)
    const [ loadingOrder, setLoadingOrder ] = useState(true)

    const getUser = async () => {
        try {
            const response = await axios.get(window.RAYAN_API + 'users?id=' + id)
            setUser(response.data[0])
        }catch (e) {
            console.log('error getting admins', e)
        }

        setLoadingUser(false)
    }

    const getOrders = async () => {
        try {
            const response = await axios.get(window.RAYAN_API + 'orders?userId=' + id)
            setOrders(response.data)
        }catch (e) {
            console.log('error getting admins', e)
        }
        setLoadingOrder(false)
    }

    useEffect(() => {
        getUser()
        getOrders()
    },[])

    if(loadingOrder || loadingUser) return <CircularProgress />

    return(
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Button variant='contained' onClick={goBack}>
                        Back
                    </Button>
                </Toolbar>
            </AppBar>
            <p style={{ padding: '10px'}} className='order-item__date'>Username: {user.username}</p>
            <DataGrid
                onRowClick={(item) => history.push(`/orders/${item._id}`)}
                data={orders}
                headers={[
                    {title: 'Date',
                        key: 'date',
                        formatter: text => moment(text).format('DD/MM/YYYY hh:mm')
                    },
                    {
                        title: 'Buyer',
                        key: 'username'
                    },
                    {
                        title: 'Status',
                        key: 'orderStatus'
                    }
                ]}
            />
        </div>
    )
}

export default User;

import './App.scss';
import LoginWidget from "./components/LoginWidget";
import React from "react";
import {useSelector} from "react-redux";
import getApp from "./apps/getApp";
import {Redirect, Route, BrowserRouter as Router, Switch, Link} from "react-router-dom";
import GlobalModal from "./components/GlobalModal";
import GlobalDialogue from "./components/GlobalDialogue";
import GlobalSnack from "./components/GlobalSnack";
import Logo from "./components/Logo";

function App() {
    const user = useSelector( state => state.auth )
    return (
        <div className="App">
            <GlobalModal />
            <GlobalDialogue />
            <GlobalSnack />
            {user.loggedIn ?
                getApp(user.roles) :
                <Router>
                    <Switch>
                        <Route exact path='/'>
                            <div className='login-page'>
                                <Logo />
                                <LoginWidget endpoint='signin'/>
                            </div>
                        </Route>
                        <Redirect to='/'/>
                    </Switch>
                </Router>
            }
        </div>
    );
}

export default App;

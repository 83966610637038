import {ACTIONS} from "./actions";

const initialState = {
    loggedIn: !!localStorage.getItem('token'),
    roles: localStorage.getItem('roles') || [],
    username: localStorage.getItem('username') || '',
    id: localStorage.getItem('userId')
}

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.LOG_IN_SUCCESS:
            return {
                loggedIn: true,
                roles: action.payload.roles,
                username: action.payload.username,
                id: action.payload.id,
                color: action.payload.color,
            }
        case ACTIONS.LOG_OUT:
            return {
                loggedIn: false,
                roles: [],
                username: '',
                id: '',
                color: ''
            }
        default:
            return state
    }
}

export default AuthReducer
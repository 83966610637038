import {combineReducers, createStore} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import authReducer from "./auth/reducer";
import modalReducer from "./modal/reducer";
import dialogueReducer from "./dialogue/reducer";
import SnackReducer from "./snack/reducer";
import ProductsReducer from "./products/reducer";
import OrdersReducer from "./orders/reducer";

const store = createStore(
    combineReducers({
        auth: authReducer,
        modal: modalReducer,
        dialogue: dialogueReducer,
        snack: SnackReducer,
        products: ProductsReducer,
        order: OrdersReducer
    }),
    {},
    composeWithDevTools()
)

export default store
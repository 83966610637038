import React from "react";
import './styles.scss';

import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'

import Dashboard from './views/dashboard';

const Operator = () =>(
    <div className="packager">
      <Router>
        <Switch>
          <Route exact path='/packager'>
            <Dashboard />
          </Route>
            <Redirect to='/packager'/>
        </Switch>
      </Router>
    </div>
)


export default Operator
import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import SideBarMenu from "./componenets/SideBarMenu";
import Admins from './views/admins';
import Orders from './views/orders';
import Products from './views/products';
import Operators from './views/operators';
import Packagers from './views/packagers';
import Users from './views/users';
import './styles.scss'
import {useSelector} from "react-redux";
import Order from './views/order';
import User from './views/user';
import Dashboard from './views/dashboard';
import Sales from './views/sales';
const SuperAdmin = () =>{
    const user = useSelector( state => state.auth )
    const isSuperAdmin = user.roles.includes('ROLE_SUPERADMIN')
    return (
        <Router>
            <SideBarMenu />
            <div className="content">
                <Switch>
                    <Route exact path='/' >
                        <Dashboard />
                    </Route>
                    <Route path='/orders' exact >
                        <Orders />
                    </Route>
                    <Route path='/orders/:id' exact >
                        <Order />
                    </Route>
                    {isSuperAdmin &&
                    <Route path='/admins' >
                        <Admins />
                    </Route>}

                    <Route path='/products' >
                        <Products />
                    </Route>

                    <Route path='/sales' >
                        <Sales />
                    </Route>

                    <Route path='/operators' >
                        <Operators />
                    </Route>

                    <Route path='/packagers' >
                        <Packagers />
                    </Route>

                    <Route path='/users' exact >
                        <Users />
                    </Route>
                    <Route path='/users/:id' exact >
                        <User />
                    </Route>
                    <Redirect to="/" />

                </Switch>
            </div>
        </Router>
    )
}

export default SuperAdmin
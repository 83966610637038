import Icon from "@mdi/react";
import {mdiDeleteOutline} from "@mdi/js";
import React, {useEffect, useRef, useState} from "react";
import './styles.scss'
import {Button} from "@material-ui/core";

const QuantityControls = ({quantity, updateQuantity, removeQuantity}) => {
    const inputEl = useRef(null);
    const [showAddBtn, setShowAddBtn] = useState(false)

    useEffect(() => {
        runDisplayLogic()
    }, [quantity])

    const runDisplayLogic = () => {
        const hasFocus = inputEl.current === document.activeElement
        setShowAddBtn(!quantity && !hasFocus)
    }

    return (
        <div className="quantity-controls">
            <Button className={`quantity-controls__add-btn  ${showAddBtn ? '' : 'hide'}`}
                    variant='outlined'
                    color='primary'
                    onClick={() => {
                        updateQuantity('')
                        inputEl.current.focus()
                    }}>
                Add to basket
            </Button>
            <div className={`quantity-controls__input ${showAddBtn ? 'hide' : ''}`}>
                <Icon path={mdiDeleteOutline}
                      size={1}
                      style={{cursor: 'pointer', color: 'red'}}
                      onClick={removeQuantity}
                />
                <input
                    type="number"
                    min="1"
                    max="9999"
                    value={quantity}
                    inputMode="numeric"
                    ref={inputEl}
                    onBlur={(e) => !e.target.value && removeQuantity() }
                    onChange={e => {
                        const quantity = e.target.value;
                        if(quantity > 15000) return
                        updateQuantity(quantity)
                    }}/>
            </div>

        </div>
    )
}

export default QuantityControls
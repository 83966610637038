import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import {Button} from "@material-ui/core";
import './styles.scss'
import {useDialogue} from "../../redux/dialogue/hooks";

const ConfirmationDialogue = ({ confirmLabel, cancelLabel, onConfirm, onCancel, title }) => {

    const { hideDialogue } = useDialogue()

    const confirmAndClose = () => {
        hideDialogue()
        onConfirm()
    }

    const closeDialogue = () => {

        hideDialogue()
        if(onCancel){
            onCancel()
        }
    }

    return (
        <Dialog aria-labelledby="confirm-dialog" open={true} className="confirm-dialogue">
            <DialogTitle id="simple-dialog-title" style={{
                textAlign: 'center'
            }}>{title}</DialogTitle>
            <div className='confirm-dialogue__btn-bar'>
                <Button style={{marginRight: 10}} variant="outlined" color="secondary" onClick={closeDialogue}>{cancelLabel || 'Cancel'}</Button>
                <Button variant="contained" color="primary" onClick={confirmAndClose}>{confirmLabel || 'Ok'}</Button>
            </div>
        </Dialog>
    );
}

export default ConfirmationDialogue
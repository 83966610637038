import React, {useEffect, useMemo, useState} from 'react';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useHistory, useParams} from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import {useDialogue} from '../../../../redux/dialogue/hooks';
import {useSnack} from '../../../../redux/snack/hooks';
import {DIALOGUES} from "../../../../redux/dialogue/consts";
import {capitalStart} from "../../../../utils/string-manipulation";
import moment from 'moment';
import {useProducts} from "../../../../redux/products/hooks";

const OrderItem = () => {
  const { id, status, date } = useParams()
  const history = useHistory()
  const { showDialogue } = useDialogue()

  const [order, setOrder] = useState([])
  const [loading, setLoading] = useState(true)
  const {isLoading: isProductsLoading, getProductNameById} = useProducts()

  const getOrder = async () => {
    setLoading(true)
    try {
      const response = await axios.get(window.RAYAN_API + `orderitem?orderId=${id}`)
      setOrder(response.data)
    }catch (e) {
      showDialogue(DIALOGUES.SIMPLE, { title : e.response?.data.message || 'Error'})
    }
    setLoading(false)
  }

  useEffect( () => {
    getOrder()
  }, [])

  if(loading || isProductsLoading) return <CircularProgress />

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Button variant="outlined" style={{color: 'white', borderColor: 'white'}} onClick={history.goBack} >Back</Button>
          <p className='order-item__name'>{capitalStart(status)}</p>
          <p className={`order-item__name`}>{moment(parseInt(date)).format('DD MMM')}</p>
        </Toolbar>
      </AppBar>
      <div style={{ paddingBottom: '60px' }}>
        {order.map((item, i) => {
            if (item.quantity === 0) return null
            return (
              <div key={i} className="order-item">
                <p className='order-item__name'>{getProductNameById(item.productId)}</p>
                <p className='order-item__name'>{item.quantity}</p>
              </div>)
          }
        )}
      </div>
    </div>
  )
}

export default OrderItem;
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import axios from 'axios';
import {useProducts} from "../../../../redux/products/hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import PackagerGrid from '../../../Packager/components/PackagerGrid';

const Dashboard = () => {
  const [ dashboardData, setDashboardData ] = useState([]);
  const { products, isLoading: isProductsLoading } = useProducts()

  const getDashboard = async () => {
    try {
      const response = await axios.get(window.RAYAN_API + `picker-dashboard`)
      setDashboardData(response.data)
    }catch (e) {
      console.log('error getting orders', e)
    }
  }

  useEffect( () => {
    getDashboard()
  }, [])

  if(isProductsLoading) return <CircularProgress/>

  const productsTotal = { username: 'total' , ...products.reduce((acc, product) => ({
      ...acc,
      [product._id]: dashboardData.reduce((acc, order) =>
        (!order[product._id] ? acc : acc + order[product._id].quantity - order[product._id].packagedQuantity), 0)
    }), {})
  }

  const columns = [{ title: 'Driver', key: 'username'},
    ...products.map(product => ({ title: product.name, key: product._id }))]
  return (
    <>
      <p style={{ marginTop: '-40px'}} className='order-item__date'>{moment().format('dddd, MMMM D')}</p>
      <div style={{ padding: '30px'}}>
        <PackagerGrid
          headers={columns}
          data={[productsTotal, ...dashboardData]}
          getDashboard={getDashboard}
        />
      </div>
    </>
  )
}

export default Dashboard;

import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

import {capitalStart} from "../../../../utils/string-manipulation";
import useAuth from '../../../../redux/auth/hooks';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import CircularProgress from "@material-ui/core/CircularProgress";

const OrderHistory = () => {
  const [loading , setLoading] = useState(true)
  const [orders , setOrders] = useState([])
  const history = useHistory()
  const { id } = useAuth();


  //API limits this to 30 orders
  const getOrderHistory = async () => {
    try {
      const response = await axios.get(window.RAYAN_API + `orders-history?userId=${id}`)
      setOrders(response.data)
    }catch (e) {
      console.log('error getting orders', e)
    }
    setLoading(false)
  }

  useEffect( () => {
    window.scrollTo(0, 0)
    getOrderHistory()
  }, [])

  if(loading) return <CircularProgress />


  return (
    <div>
      <AppBar position="fixed">
        <Toolbar variant='dense'>
          <Button variant="outlined" style={{color: 'white', borderColor: 'white'}} onClick={history.goBack} >Back</Button>
        </Toolbar>
      </AppBar>
      <p className='order-item__date'>Order History</p>
      {(orders.length > 0) ?
        orders.map(({ orderStatus, username, _id, date }, i) =>
          <div key={i} className="order-item" onClick={() => history.push(`/order-item/${_id}/${orderStatus}/${date}`)}>
            <div>
              <p className='order-item__label'>STATUS</p>
              <p className='order-item__name'>{capitalStart(orderStatus)}</p>
            </div>
            <div>
              <p className='order-item__label'>DATE</p>
              <p className='order-item__name'>{moment(date).format('DD MMM')}</p>
            </div>
          </div>)
        : <p>You have no orders</p>}
    </div>
  )
}

export default OrderHistory
import React from "react";
import Modal from "@material-ui/core/Modal";
import {useModal} from "../../redux/modal/hooks";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import './styles.scss'
import {useProducts} from "../../redux/products/hooks";
import CircularProgress from "@material-ui/core/CircularProgress";

const ConfirmOrderModal = ({basket, onConfirm}) => {
    const {hideModal} = useModal()
    const { isLoading, getProductNameById } = useProducts()

    const handleConfirm = () => {
        hideModal()
        onConfirm()
    }

    if(isLoading){
        return <CircularProgress />
    }
    return(
        <Modal onBackdropClick={hideModal} open={true} className='confirm-order-modal'>
            <div className='confirm-order-modal__content'>
                <DialogTitle>Please confirm order</DialogTitle>
                <div className="table-holder">
                <table>
                    <tbody>
                    {Object.keys(basket).map( productId => {
                        if(basket[productId] > -1){
                            return(
                                <tr>
                                    <td>{getProductNameById(productId)}</td>
                                    <td className='amount'>{basket[productId]}</td>
                                </tr>)
                        }
                        return null
                    })}
                    </tbody>
                </table>
                </div>
                <div className='footer-btn-bar'>
                    <Button variant='outlined' color='secondary' onClick={hideModal}>
                        Close
                    </Button>

                    <Button variant='contained' color='primary' onClick={handleConfirm}>
                        Confirm
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmOrderModal
import React, {useEffect, useState} from 'react';
import DataGrid from '../../componenets/DataGrid';
import axios from 'axios';
import {useProducts} from "../../../../redux/products/hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import {capitalStart} from '../../../../utils/string-manipulation';

const Dashboard = () => {
  const [ dashboardData, setDashboardData ] = useState([]);
  const [ loading, setLoading ] = useState(true)
  const { products, isLoading: isProductsLoading } = useProducts()

  const getDashboard = async () => {
    try {
      const response = await axios.get(window.RAYAN_API + `dashboard`)
      setDashboardData(response.data)
    }catch (e) {
      console.log('error getting orders', e)
    }
    setLoading(false)
  }

  useEffect( () => {
    getDashboard()
  }, [])


  if(loading || isProductsLoading) return <CircularProgress />

  const columns = [{ title: 'Driver', key: 'username', formatter: text => <div style={{ fontWeight: 'bold' }}>{capitalStart(text)}</div> },
    ...products.map(product => ({ title: product.name, key: product._id }))]




  return (
    <DataGrid
      headers={columns}
      data={dashboardData}
    />
  )
}

export default Dashboard;

export const ACTIONS = {
    SET_PRODUCTS:'SET_PRODUCTS',
}

export const setProducts = (products) => {
    return {
        type: ACTIONS.SET_PRODUCTS,
        payload:{
            products
        }
    }
}
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';

import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import store from './redux/store'
import {logout} from "./redux/auth/actions";

const token = localStorage.getItem('token');
if (token) axios.defaults.headers['x-access-token'] = token;


window.RAYAN_API = 'https://rayan-api-dot-rayan-305323.ew.r.appspot.com/'
// window.RAYAN_API = 'http://localhost:8080/'


// a global 401 handler
axios.interceptors.response.use(null, (err) => {
        if(err.response.status === 401){
            store.dispatch(logout())
            return
        }
        throw err
    }
);

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);


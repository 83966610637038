import React, {useEffect, useState} from "react";
import isEmpty from 'lodash.isempty';
import ImageUploader from 'react-images-upload';
import Modal from '@material-ui/core/Modal';
import './styles.scss';
import {Button, TextField} from "@material-ui/core";
import axios from 'axios';
import {useModal} from "../../../../redux/modal/hooks";
import {DIALOGUES} from "../../../../redux/dialogue/consts";
import {useDialogue} from "../../../../redux/dialogue/hooks";
import CircularProgress from "@material-ui/core/CircularProgress";

const EditAddProductModal = ({isAddMode, product, onSuccess}) => {

  const [name, setName] = useState('')
  const [imageUrl, setImageUrl] = useState([])
  const [loading, setLoading] = useState(false)
  const { hideModal } = useModal()
  const { showDialogue } = useDialogue()


  useEffect( () => {
    if(!isAddMode) {
      setName(product.name)
      product.imageUrl && setImageUrl(product.imageUrl)
    }
  }, [product])

  const shouldDisableSaveBtn = () => {
    if (isEmpty(name)) return true
    if(imageUrl !== product?.imageUrl) return false
    return product.name === name;
  }

  const submitProduct = async () => {
    let data = { name }
    if(!isEmpty(imageUrl)) {
      data = new FormData()
      data.append('file', imageUrl[0])
      name !== product.name && data.append('name', name)
    }
    if (isAddMode){
      await axios.post(window.RAYAN_API + 'products', data)
    } else {
      await axios.put(window.RAYAN_API + 'products/' + product._id, data)
    }
  }

  const editAddProduct = async () => {
    setLoading(true)
    try {
      await submitProduct()
      onSuccess()
      hideModal()
    }catch (e) {
      showDialogue(DIALOGUES.SIMPLE, {
        title: e.response?.data.message || 'Error'
      })
    }
    setLoading(false)
  }

  return(
      <Modal onBackdropClick={hideModal} open={true} className='edit-product-modal'>
        <div className='edit-product-modal__content'>
          {loading ? <CircularProgress /> :
              <>
                <h2 id="modal-title">
                  {isAddMode ? 'Add ' : 'Edit '} Product
                </h2>
                <p id="modal-description">
                  Enter new details below and then click save.
                </p>
                <ImageUploader
                    className="edit-product-modal__image-uploader"
                    withIcon={true}
                    buttonText='Choose images'
                    onChange={setImageUrl}
                    imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                    maxFileSize={2542880}
                    withPreview
                    singleImage
                />
                <TextField label="name"  type="text" value={name} onChange={e => setName(e.target.value)}/>
                <div className='edit-product-modal__action-bar'>
                  <Button style={{marginRight: 10}} variant="outlined" color="secondary" onClick={hideModal}>Cancel</Button>
                  <Button variant="contained" color="primary" disabled={shouldDisableSaveBtn()} onClick={editAddProduct}>{isAddMode? 'Add' : 'Save'}</Button>
                </div>
              </>}
        </div>
      </Modal>
  )
}

export default EditAddProductModal
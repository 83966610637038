import React, {useEffect, useState} from "react";
import axios from "axios";
import DataGrid from "../../componenets/DataGrid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import {useModal} from "../../../../redux/modal/hooks";
import {MODALS} from "../../../../redux/modal/consts";
import {DIALOGUES} from "../../../../redux/dialogue/consts";
import {useDialogue} from "../../../../redux/dialogue/hooks";
import {useSnack} from "../../../../redux/snack/hooks";
import {useHistory} from 'react-router-dom';
import useAuth from "../../../../redux/auth/hooks";
import CircularProgress from "@material-ui/core/CircularProgress";

const Users = () => {

    const [ users, setUsers ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const { showModal } = useModal()
    const { showDialogue } = useDialogue()
    const { showSnack } = useSnack()
    const { isSuperAdmin } = useAuth()

    const history = useHistory();

    useEffect( () => {
        getUsers()
    }, [])

    const getUsers = async () => {
        try {
            const response = await axios.get(window.RAYAN_API + 'users?roles=user')
            setUsers(response.data)
        }catch (e) {
            showSnack(e.response?.data.message, 'error')
        }
        setLoading(false)
    }

    const deleteUser = async user => {
        try {
            await axios.delete(window.RAYAN_API + 'users?id=' + user.id)
            showSnack('User deleted')
            getUsers()
        }catch (e) {
            showSnack(e.response?.data.message, 'error')
        }
    }


    const editUser = user => {
        showModal(MODALS.EDIT_ADD_USER, {
            isAddMode: false,
            user,
            onSuccess: () => {
                showSnack(`User updated successfully`)
                getUsers()
            }
        })
    }

    const addUser = () => {
        showModal(MODALS.EDIT_ADD_USER, {
            isAddMode: true,
            onSuccess: () => {
                showSnack(`User added successfully`)
                getUsers()
            }
        })
    }

    const openDeleteConfirmation = user => {
        showDialogue(DIALOGUES.CONFIRMATION, {
            onConfirm: () => deleteUser(user),
            title: `Are you sure you want to delete ${user.username}`
        })
    }

    if(loading) return <CircularProgress />

    return (
      <div >
          <AppBar position="static">
              <Toolbar>
                  {isSuperAdmin &&<Button variant='contained' onClick={addUser}>
                      Add User
                  </Button>}
              </Toolbar>
          </AppBar>


          <DataGrid
            onRowClick={(item) => history.push(`/users/${item.id}`)}
            headers={[{title: 'Username', key: 'username'}]}
            data={users}
            isSuperAdmin={isSuperAdmin}
            openEditModal={editUser}
            openDeleteConfirmation={openDeleteConfirmation}
          />
      </div>);
}

export default Users;
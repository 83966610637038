import {useDispatch, useSelector} from "react-redux";
import {setProducts as setProductsAction} from "./actions";
import {useEffect, useState} from "react";
import axios from "axios";
import {useSnack} from "../snack/hooks";

export const useProducts = () => {
    const {products, hasLoaded} = useSelector(state => state.products);
    const [isLoading, setIsLoading] = useState(true)
    const [productIdToName, setProductIdToName] = useState({})
    const dispatch = useDispatch()
    const {showSnack} = useSnack()


    const setProducts = (products) => {
        dispatch(setProductsAction(products))
    }

    const getProducts = async () => {
        setIsLoading(true)
        try {
            const response = await axios.get(window.RAYAN_API + 'products')
            setProducts(response.data)
            const productIdToName = createProductToIdMap(response.data)
            setProductIdToName(productIdToName)
        }catch (e) {
            showSnack(e.response?.data.message, 'error')
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if(!hasLoaded){
            getProducts()
        }else{
            const productIdToName = createProductToIdMap(products)
            setProductIdToName(productIdToName)
            setIsLoading(false)
        }
    }, [])

    const getProductNameById = id => {
        if(productIdToName[id]) return productIdToName[id]
        return false
    }

    const createProductToIdMap = (products) => products.reduce( (acc, product) => {
            acc[product._id] = product.name
            return acc
        }, {})

    return {
        products,
        isLoading,
        getProducts,
        getProductNameById,
    }
}
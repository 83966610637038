import {ACTIONS} from "./actions";

const initialState = {
    filters: {
        date:{
            to:new Date(),
            from:new Date()
        }
    }
}

const OrdersReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_TO_DATE:
            return {
                filters: {
                    date:{
                        ...state.filters.date,
                        to: action.payload.toDate
                    }
                }
            }
        case ACTIONS.SET_FROM_DATE:
            return {
                filters: {
                    ...state.filters,
                    date:{
                        ...state.filters.date,
                        from:action.payload.fromDate
                    }
                }
            }
        case ACTIONS.SET_CURRENT_USER:
            return {
                filters: {
                    ...state.filters,
                    user: action.payload.user
                }
            }
        default:
            return state
    }
}

export default OrdersReducer
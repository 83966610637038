import React, {useEffect, useState} from "react";
import axios from 'axios';
import DataGrid from "../../componenets/DataGrid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import {useModal} from "../../../../redux/modal/hooks";
import {MODALS} from "../../../../redux/modal/consts";
import {useDialogue} from "../../../../redux/dialogue/hooks";
import {DIALOGUES} from "../../../../redux/dialogue/consts";
import {useSnack} from "../../../../redux/snack/hooks";
import {useProducts} from "../../../../redux/products/hooks";
import useAuth from "../../../../redux/auth/hooks";
import CircularProgress from "@material-ui/core/CircularProgress";

const Products = () => {
    const { showModal } = useModal()
    const { showDialogue } = useDialogue()
    const { showSnack } = useSnack()
    const { products, getProducts, isLoading } = useProducts()
    const { isSuperAdmin } = useAuth()


    const showAddProductModal = () => {
        showModal(MODALS.EDIT_ADD_PRODUCT, {
            isAddMode : true,
            onSuccess: () => {
                showSnack(`Product added successfully`)
                getProducts()
            }
        })
    }

    const editProduct = (product) => {
        showModal(MODALS.EDIT_ADD_PRODUCT, {
            isAddMode : false,
            product,
            onSuccess: () => {
                showSnack(`Product edited successfully`)
                getProducts()
            }
        })
    }

    const openDeleteConfirmation = product => {
        showDialogue(DIALOGUES.CONFIRMATION, {
            onConfirm: () => deleteProduct(product),
            title: `Are you sure you want to delete ${product.name}`
        })
    }

    const deleteProduct = async product => {
        try {
            await axios.delete(window.RAYAN_API + 'products/' + product._id)
            getProducts()
            showSnack('Product deleted')
        }catch (e) {
            showDialogue(DIALOGUES.SIMPLE, {
                title: e.response?.data.message || 'Error'
            })
        }
    }

    if(isLoading) return <CircularProgress />

    return(
        <div>
            <AppBar position="static">
                <Toolbar>
                    {isSuperAdmin && <Button variant='contained' onClick={showAddProductModal}>
                        Add Product
                    </Button>}
                </Toolbar>
            </AppBar>

            <DataGrid
                headers={[
                    {title: 'Name', key: 'name'},
                    { title: 'Image', key: 'imageUrl', formatter: (url) => url &&  <img alt='product-thumbnail' style={{ width: '40px', height: '40px'}} src={url} />}
                ]}
                data={products}
                isSuperAdmin={isSuperAdmin}
                openEditModal={editProduct}
                openDeleteConfirmation={openDeleteConfirmation}
            />
        </div>
    )
};

export default Products;
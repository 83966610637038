import {ACTIONS} from "./actions";

const initialState = {
    isOpen: false,
    name: '',
    props: {}
}

const ModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SHOW_MODAL:
            return {
                isOpen: true,
                name: action.payload.name,
                props: action.payload.props
            }
        case ACTIONS.HIDE_MODAL:
            return {
                isOpen: false,
                name: '',
                props: {}
            }
        default:
            return state
    }
}

export default ModalReducer
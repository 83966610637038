import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import {useDispatch} from 'react-redux';
import {logout as logoutAction} from '../../../../redux/auth/actions';
import {useDialogue} from '../../../../redux/dialogue/hooks';
import {DIALOGUES} from '../../../../redux/dialogue/consts';
import Logo from "../../../../components/Logo";
import {capitalStart} from "../../../../utils/string-manipulation";

const DailyOrders = () => {
  const [todaysOrders , setTodaysOrders] = useState([])
  const history = useHistory()
  const { showDialogue } = useDialogue()

  const getTodaysOrders = async () => {
    const now = moment()
    const startTime = moment("00:00", 'HH:mm')
    const cutOffTime = moment("08:59", 'HH:mm')

    let from = now.startOf('day').valueOf()
    let to = now.endOf('day').valueOf()

    // if current time is between 12am and 9am, get yesterday orders as they'll be handing over orders throughout the night
    if (moment().isBetween(startTime, cutOffTime)) {
      from = moment().subtract(1,'days').startOf('day').valueOf()
      to = moment().subtract(1,'days').endOf('day').valueOf()
    }

    try {
      const response = await axios.get(window.RAYAN_API + `orders?dateFrom=${from}&dateTo=${to}`)
      setTodaysOrders(response.data)
    }catch (e) {
      console.log('error getting orders', e)
    }
  }

  const onLogoutConfirm = () => {
    showDialogue(DIALOGUES.CONFIRMATION, {
      onConfirm: logout,
      confirmLabel: 'Yes',
      cancelLabel: 'No',
      title: `Logout?`
    })
  }

  useEffect( () => {
    window.scrollTo(0, 0)
    getTodaysOrders()
  }, [])

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(logoutAction())
  }




  const sortStatus = (a, b) => {
    if (a.orderStatus === 'complete' || a.orderStatus === 'complete-paid') return 1;
    if (b.orderStatus === 'placed') return 0;
    return -1;
  }


  return (
      <div>
        <AppBar position="fixed">
          <Toolbar>
            <Logo size={60}/>
            <Button variant={"contained"} onClick={onLogoutConfirm} style={{marginLeft: 'auto'}}>Log out</Button>
          </Toolbar>
        </AppBar>
        <p className='order-item__date'>{moment().format('dddd, MMMM D')}</p>
        {(todaysOrders.length > 0) ?
            todaysOrders.sort(sortStatus).map(({ orderStatus, username, _id }, i) =>
                <div key={i} className={`order-item order-item__${orderStatus}`} onClick={() => history.push(`/order-item/${_id}/${orderStatus}/${username}`)}>
                  <div>
                    <p className='order-item__label'>DRIVER</p>
                    <p className='order-item__name'>{capitalStart(username)}</p>
                  </div>
                  <div>
                    <p className='order-item__label'>STATUS</p>
                    <p className='order-item__name'>{capitalStart(orderStatus)}</p>
                  </div>
                </div>)
            : <p>no orders received today</p>}
      </div>
  )
}

export default DailyOrders
import React from "react";
import EditAddAdminModal from "../../apps/SuperAdmin/componenets/EditAddAdminModal";
import EditAddOperatorModal from "../../apps/SuperAdmin/componenets/EditAddOperatorModal";
import EditAddUserModal from "../../apps/SuperAdmin/componenets/EditAddUserModal";
import EditAddPackagerModal from '../../apps/SuperAdmin/componenets/EditAddPackagerModal';
import EditAddProductModal from "../../apps/SuperAdmin/componenets/EditAddProductModal";
import ConfirmOrderModal from "../../components/ConfirmOrderModal";
import {MODALS} from "../../redux/modal/consts";
import {useModal} from "../../redux/modal/hooks";

const GlobalModal = () => {
    const { isOpen, name,props } = useModal()
    if(!isOpen) return null
    switch (name) {
        case MODALS.EDIT_ADD_ADMIN:
            return <EditAddAdminModal {...props} />
        case MODALS.EDIT_ADD_OPERATOR:
            return <EditAddOperatorModal {...props} />
        case MODALS.EDIT_ADD_PACKAGER:
            return <EditAddPackagerModal {...props} />
        case MODALS.EDIT_ADD_USER:
            return <EditAddUserModal {...props} />
        case MODALS.EDIT_ADD_PRODUCT:
            return <EditAddProductModal {...props} />
        case MODALS.CONFIRM_ORDER:
            return <ConfirmOrderModal {...props} />
        default:
            return null
    }
}

export default GlobalModal
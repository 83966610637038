import React, {useEffect, useState} from "react";
import isEmpty from 'lodash.isempty';
import { useParams, useHistory } from "react-router-dom";
import axios from 'axios';
import DataGrid from '../../componenets/DataGrid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import {useProducts} from "../../../../redux/products/hooks";

const Order = () => {
    const { id } = useParams();
    const { goBack } = useHistory();
    const [orderItems, setOrderItems] = useState([])
    const { products, isLoading: isProductsLoading } = useProducts()
    const [loading, setLoading] = useState(true)

    const getOrders = async () => {
        try {
            const response = await axios.get(window.RAYAN_API + 'orderitem?orderId=' + id)
            setOrderItems(response.data)
        }catch (e) {
            console.log('error getting admins', e)
        }
        setLoading(false)
    }

    useEffect( () => {
        getOrders()
    }, [])

    const transformData = () => {
        return orderItems
            //remove orders with deleted products
            .filter( item => products.find(product => product._id === item.productId))
            .map(item => {
                const { name, imageUrl } = products.find(product => product._id === item.productId)
                return { ...item, name, imageUrl }
            })
    }

    if(loading || isProductsLoading) return <CircularProgress />

    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Button variant='contained' onClick={goBack}>
                        Back
                    </Button>

                    {}
                </Toolbar>
            </AppBar>

            <DataGrid
                headers={[
                    {title: 'Name', key: 'name'},
                    {title: 'Quantity', key: 'quantity'},
                    {title: 'Collected Quantity', key: 'collectedQuantity'},
                    { title: 'Image', key: 'imageUrl', formatter: (url) => url &&  <img  alt='product-thumbnail' style={{ width: '40px', height: '40px'}} src={url} />}
                ]}
                data={transformData()}
            />
        </div>
    )
}
export default Order;
import React from "react";
import SimpleDialog from "../SimpleDialogue";
import {useDialogue} from "../../redux/dialogue/hooks";
import {DIALOGUES} from "../../redux/dialogue/consts";
import ConfirmationDialogue from "../ConfirmationDialogue";

const GlobalDialogue = () => {
    const { isOpen, name ,props } = useDialogue()
    if(!isOpen) return null
    switch (name) {
        case DIALOGUES.SIMPLE:
            return <SimpleDialog {...props} />
        case DIALOGUES.CONFIRMATION:
            return <ConfirmationDialogue {...props} />
        default:
            return null
    }
}

export default GlobalDialogue
import React from "react";
import logoPath from '../../assets/logo.png'

const Logo = ({size = 100, style }) => {
    return(
        <img src={logoPath} alt='logo' style={{
            width: size,
                ...style
        }}/>
    )
}

export default Logo
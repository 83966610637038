export const ACTIONS = {
    SHOW_DIALOGUE:'SHOW_DIALOGUE',
    HIDE_DIALOGUE:'HIDE_DIALOGUE'
}

export const showDialogue = (name, props) => {
    return {
        type: ACTIONS.SHOW_DIALOGUE,
        payload:{
            name,
            props
        }
    }
}
export const hideDialogue = () => {
    return {
        type: ACTIONS.HIDE_DIALOGUE
    }
}
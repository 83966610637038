import React, {useEffect, useState} from "react";
import axios from "axios";
import DataGrid from "../../componenets/DataGrid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import {useModal} from "../../../../redux/modal/hooks";
import {MODALS} from "../../../../redux/modal/consts";
import {DIALOGUES} from "../../../../redux/dialogue/consts";
import {useDialogue} from "../../../../redux/dialogue/hooks";
import {useSnack} from "../../../../redux/snack/hooks";
import useAuth from "../../../../redux/auth/hooks";
import CircularProgress from "@material-ui/core/CircularProgress";

const Operators = () => {

  const [ operators, setOperators ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const { showModal } = useModal()
  const { showDialogue } = useDialogue()
  const { showSnack } = useSnack()
  const { isSuperAdmin } = useAuth()


  useEffect( () => {
    getOperators()
  }, [])

  const getOperators = async () => {
    try {
      const response = await axios.get(window.RAYAN_API + 'users?roles=operator')
      setOperators(response.data)
    }catch (e) {
      console.log('error getting operators', e)
    }
    setLoading(false)
  }

  const deleteOperator = async operator => {
    try {
      await axios.delete(window.RAYAN_API + 'users?id=' + operator.id)
      getOperators()
      showSnack('Operator deleted')
    }catch (e) {
      console.log('error deleting operator')
    }
  }


  const editOperator = operator => {
    showModal(MODALS.EDIT_ADD_OPERATOR, {
      isAddMode: false,
      operator,
      onSuccess: () => {
        showSnack(`Operator updated successfully`)
        getOperators()
      }
    })
  }

  const addOperator = () => {
    showModal(MODALS.EDIT_ADD_OPERATOR, {
      isAddMode: true,
      onSuccess: () => {
        showSnack(`Operator added successfully`)
        getOperators()
      }
    })
  }

  const openDeleteConfirmation = operator => {
    showDialogue(DIALOGUES.CONFIRMATION, {
      onConfirm: () => deleteOperator(operator),
      title: `Are you sure you want to delete ${operator.username}`
    })
  }

  if(loading) return <CircularProgress />

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          {isSuperAdmin &&<Button variant='contained' onClick={addOperator}>
            Add Operator
          </Button>}
        </Toolbar>
      </AppBar>

      <DataGrid
        headers={[{title: 'Username', key: 'username'}]}
        data={operators}
        openEditModal={editOperator}
        isSuperAdmin={isSuperAdmin}
        openDeleteConfirmation={openDeleteConfirmation}
      />
    </div>);
}

export default Operators;
import axios from 'axios';
export const ACTIONS = {
    LOG_IN_SUCCESS: 'AUTH/LOG_IN_SUCCESS',
    LOG_OUT: 'AUTH/LOG_OUT',
}

export const LoginSuccessful = (data) => {
    if (isLocalStorageAvailable()) {
        localStorage.setItem('token', data.accessToken)
        localStorage.setItem('roles', data.roles)
        localStorage.setItem('userId', data.id)
        localStorage.setItem('username', data.username)
    }
    axios.defaults.headers['x-access-token'] = data.accessToken;
    const payload = {
        roles: data.roles,
        username: data.username,
        id: data.id,
    }
    if (data.color) {
        localStorage.setItem('color', data.color)
        payload.color = data.color;
    }

    return {
        type: ACTIONS.LOG_IN_SUCCESS,
        payload
    }
}


export const logout = () => {
    if (isLocalStorageAvailable()) {
        localStorage.removeItem('token')
        localStorage.removeItem('roles')
        localStorage.removeItem('userId')
        localStorage.removeItem('username')
        localStorage.removeItem('color')
    }
    return {
        type: ACTIONS.LOG_OUT
    }
}

const isLocalStorageAvailable = () =>{
    var test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}

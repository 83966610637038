import {useDispatch, useSelector} from "react-redux";
import {setToDate as setToDateAction, setFromDate as setFromDateAction, setCurrentUser} from "./actions";

export const useOrders = () => {
    const filters = useSelector(state => state.order.filters);
    const dispatch = useDispatch()


    const setToDate = date => {
        dispatch(setToDateAction(date))
    }

    const setFromDate = date => {
        dispatch(setFromDateAction(date))
    }

    const setUser = user => {
        dispatch(setCurrentUser(user))
    }

    return {
        setToDate,
        setFromDate,
        setUser,
        filters
    }
}
import React from "react";
import './styles.scss'
import QuantityControls from "../QuantityControls";

const ProductItem = ({product, quantity, updateQuantity, removeQuantity, isOrderingLocked}) => {
  if (isOrderingLocked && !quantity) return null;
  return(
    <div className="product-item">
      { product.imageUrl ? <img src={product.imageUrl} alt=""/> :
        <div className="product-item__default-img">{product.name[0].toUpperCase()}</div>
      }
      <div className="product-item__right-section">
        <p className='product-item__name'>{product.name}</p>
        <p className='product-item__basket-text'>{quantity > 0 && `${quantity} in basket`}</p>
        {!isOrderingLocked && <QuantityControls
          quantity={quantity}
          updateQuantity={(quantity) => updateQuantity(product, quantity)}
          removeQuantity={ () => removeQuantity(product)}
        />}

      </div>
    </div>
  )
}

export default ProductItem

export const ACTIONS = {
    SET_TO_DATE:'SET_TO_DATE',
    SET_FROM_DATE:'SET_FROM_DATE',
    SET_CURRENT_USER:'SET_CURRENT_USER',
}

export const setFromDate = fromDate => {
    return {
        type: ACTIONS.SET_FROM_DATE,
        payload:{
            fromDate
        }
    }
}
export const setToDate = toDate => {
    return {
        type: ACTIONS.SET_TO_DATE,
        payload:{
            toDate
        }
    }
}

export const setCurrentUser = user => {
    return {
        type: ACTIONS.SET_CURRENT_USER,
        payload:{
            user
        }
    }
}

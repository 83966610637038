import React, {useEffect, useState} from "react";
import Modal from '@material-ui/core/Modal';
import './styles.scss';
import {Button, TextField} from "@material-ui/core";
import axios from "axios";
import {useModal} from "../../../../redux/modal/hooks";
import {useDialogue} from "../../../../redux/dialogue/hooks";
import {DIALOGUES} from "../../../../redux/dialogue/consts";
import CircularProgress from "@material-ui/core/CircularProgress";

const EditAddAdminModal = ({isAddMode, admin, onSuccess}) => {

    const [username, setUsername ] = useState()
    const [password, setPassword ] = useState()
    const [loading, setLoading] = useState(false)
    const {hideModal} = useModal()
    const { showDialogue } = useDialogue()

    useEffect( () => {
        if(!isAddMode) {
            setUsername(admin.username)
            setPassword('')
        }
    }, [admin])

    const shouldDisableSaveBtn = () => {

        if(isAddMode){
            if(!username || !password) return true
        }else {
            if (password) return false
            if (username === admin?.username) return true
        }
    }

    const addAdmin = async () => {
        await axios.post(window.RAYAN_API + 'users', {
            username,
            password,
            roles:['admin']
        })
    }
    const editAdmin = async () => {
        await axios.put(window.RAYAN_API + 'users?id=' + admin.id,  JSON.parse(JSON.stringify({
            username: username === admin.username ? undefined : username,
            password: password || undefined
        })) )
    }

    const editAddAdmin = async () => {
        try {
            setLoading(true)
            if (isAddMode) {
                await addAdmin()
            } else {
                await editAdmin()
            }
            hideModal()
            onSuccess()
        }catch (e) {
            showDialogue(DIALOGUES.SIMPLE, {
                title: e.response?.data.message || 'Error'
            })
        }
        setLoading(false)

    }


    return(
        <Modal onBackdropClick={hideModal} open={true} className='edit-admin-modal'>
            <div className='edit-admin-modal__content'>
                {loading ? <CircularProgress /> :
                    <>
                        <h2 id="modal-title">
                            {isAddMode? 'Add' : 'Edit'} Admin
                        </h2>
                        <p id="modal-description">
                            Enter new details below and then click save.
                        </p>

                        <TextField label="Username"  type="text" value={username} onChange={e => setUsername(e.target.value)}/>
                        <TextField label="Password"  type="text" value={password} onChange={e => setPassword(e.target.value)}/>
                        <div className='edit-admin-modal__action-bar'>
                            <Button style={{marginRight: 10}} variant="outlined" color="secondary" onClick={hideModal}>Cancel</Button>
                            <Button variant="contained" color="primary" disabled={shouldDisableSaveBtn()} onClick={editAddAdmin}>{isAddMode? 'Add' : 'Save'}</Button>
                        </div>
                    </>}
            </div>
        </Modal>
    )
}

export default EditAddAdminModal
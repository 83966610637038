import React, {useEffect, useState} from "react";
import axios from "axios";
import './styles.scss';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import DateSelector from '../../componenets/DateSelector';
import Button from '@material-ui/core/Button';
import {useProducts} from '../../../../redux/products/hooks';
import {useOrders} from '../../../../redux/orders/hooks';
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from '@material-ui/core/Card';

const Sales = () => {

  const [ orders, setOrders ] = useState([])
  const { isLoading: isProductsLoading, getProductNameById } = useProducts()
  const { setToDate, setFromDate, filters } = useOrders()
  const getOrderItems = async () => {
    const from = filters.date.from?.setHours(0,0,0,0)
    const to = filters.date.to?.setHours(23,59,59,999)
    const dateQuery = (from && to) ? `?dateFrom=${from}&dateTo=${to}` : ''

    try {
      const response = await axios.get(window.RAYAN_API + `orderitem${dateQuery}`)
      setOrders(response.data)
    }catch (e) {
      console.log('error getting orders', e)
    }
  }

  useEffect( () => {
    getOrderItems()
    // getProducts()
  }, [filters.date.from, filters.date.to])

  useEffect( () => {
    return () => resetFilters()
  }, [])

  const getAllOrderItems = () => {
    setToDate(null)
    setFromDate(null)
  }

  const resetFilters = () => {
    setToDate(new Date())
    setFromDate(new Date())
  }

  if(isProductsLoading) return <CircularProgress />

  const ordersObj = orders.reduce((acc, item) => {
    if (!acc[item.productId]) return { ...acc, [item.productId]: item.quantity}
    return { ...acc, [item.productId]: acc[item.productId] + item.quantity}
  }, {})

  const getCards = () => Object.keys(ordersObj).filter(key => getProductNameById(key)).map(key =>
    <Card className="product-card">
      <div className="product-card__product">{getProductNameById(key)}</div>
      <div className="product-card__quantity">{ordersObj[key]}</div>
    </Card>
  )

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
        </Toolbar>
      </AppBar>
      <div style={{ display: 'flex', padding: '10px'}}>
        <DateSelector />
        <Button variant="outlined" color="primary" style={{ marginLeft: '20px'}} onClick={getAllOrderItems}>All orders</Button>
        <Button variant="text" color="secondary" style={{ marginLeft: '20px'}} onClick={resetFilters}>Reset</Button>
      </div>
      <div className="product-card__container">
        {getCards()}
      </div>
    </div>
  );
}

export default Sales;
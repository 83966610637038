import React from 'react';
import './styles.scss';
import DateFnsUtils from '@date-io/date-fns';
import {isAfter, isBefore} from 'date-fns'
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {useOrders} from "../../../../redux/orders/hooks";

const DateSelector = () => {

    const {setToDate, setFromDate, filters: { date }} = useOrders()

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div style={{display: 'flex', marginLeft: '10px', alignItems: 'flex-end'}}>
              <div style={{marginRight: '20px'}}>
                  <DatePicker
                    label="From"
                    value={date.from}
                    onChange={date => setFromDate(date)}
                    disableFuture={true}
                    shouldDisableDate={day => isAfter(day, date.to)}
                  />

              </div>
              <div style={{marginRight: '20px'}}>
                  <DatePicker
                    label="To"
                    value={date.to}
                    onChange={date => setToDate(date)}
                    disableFuture={true}
                    shouldDisableDate={day => isBefore(day, date.from)
                    }
                  />
              </div>
          </div>
      </MuiPickersUtilsProvider>
    )
}
export default DateSelector;
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {logout as logoutAction} from "../../redux/auth/actions";
import './styles.scss'
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import {BrowserRouter, Link, Redirect, Route, Switch} from "react-router-dom";
import CreateEditOrder from "./views/CreateEditOrder";
import {useDialogue} from "../../redux/dialogue/hooks";
import {DIALOGUES} from "../../redux/dialogue/consts";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSnack} from "../../redux/snack/hooks";
import Logo from "../../components/Logo";
import useAuth from "../../redux/auth/hooks";
import {capitalStart} from "../../utils/string-manipulation";
import OrderHistory from './views/OrderHistory';
import OrderItem from './views/OrderItem';

const Buyer = () =>{
    const [todaysOrder , setTodaysOrder] = useState([])
    const [isOrderingLocked, setIsOrderingLocked] = useState(false)
    const [packagingStarted, setPackagingStarted] = useState(false)
    const [loading, setLoading] = useState(false)
    const { username } = useAuth()
    const dispatch = useDispatch()
    const { showDialogue } = useDialogue()
    const { showSnack } = useSnack()
    const userId = useSelector(state => state.auth.id)

    useEffect( () => {
        window.scrollTo(0, 0)
        getTodaysOrder()
        const startTime = moment("06:00", 'HH:mm')
        const cutOffTime = moment("23:00", 'HH:mm')
        const now = moment()

        // if current time is  not between 6am and 9pm then they should not be able to place or edit orders
        if(!now.isBetween(startTime, cutOffTime)) {
            setIsOrderingLocked(true)
        }
    }, [])


    useEffect( () => {
        todaysOrder.length && getOrderItems()
    }, [todaysOrder])


    const getTodaysOrder = async () => {
        setLoading(true)
        const today = new Date()
        let query
        const startTime = moment("00:00", 'HH:mm')
        const endTime = moment("09:00", 'HH:mm')
        const now = moment()

        // if a user opens the app after midnight we need to send yesterdays date to get their order
        if(now.isBetween(startTime, endTime)){
            const yesterday = new Date(today)
            yesterday.setDate(yesterday.getDate() - 1)
            const to = yesterday.setHours(23, 59, 59, 999)
            const from = yesterday.setHours(0, 0, 0, 0)
            query = `?userId=${userId}&dateFrom=${from}&dateTo=${to}`
        }else {
            const to = today.setHours(23, 59, 59, 999)
            const from = today.setHours(0, 0, 0, 0)
            query = `?userId=${userId}&dateFrom=${from}&dateTo=${to}`
        }
        try {
            const response = await axios.get(window.RAYAN_API + `orders${query}`)
            setTodaysOrder(response.data)
        }catch (e) {
            showSnack((e.response?.data.message || 'Error cancelling order'), 'error')
        }
        setLoading(false)
    }

    const getOrderItems = async () => {
        const response = await axios.get(window.RAYAN_API + `orderitem?orderId=${todaysOrder[0]._id}`)
        const hasStarted = response.data.some(item => item.packagedQuantity > 0)
        setPackagingStarted(hasStarted)
    }

    const cancelOrder = async () => {
        showDialogue(DIALOGUES.CONFIRMATION, {
            title: 'Are you sure you want to cancel this order',
            onConfirm: async () => {
                setLoading(true)
                try {
                    await axios.delete(window.RAYAN_API + `orders?id=${todaysOrder[0]._id}`)
                    setTodaysOrder([])
                    showSnack('Order cancelled')
                } catch (e) {
                    showSnack((e.response?.data.message || 'Error cancelling order'), 'error')
                }
                setLoading(false)
            }
        })


    }

    const logout = () => {
        showDialogue(DIALOGUES.CONFIRMATION, {

            onConfirm: () => {
                dispatch(logoutAction())
            },
            confirmLabel: 'Yes',
            cancelLabel: 'No',
            title: `Logout?`
        })
    }

    const getButtons = () => {
        switch(true) {
            case (todaysOrder.length && (todaysOrder[0].orderStatus === 'complete' || todaysOrder[0].orderStatus === 'complete-paid')):
                return (
                  <div>
                      <p>You have successfully picked up your order, thank you.</p>
                  </div>
                )
            case (todaysOrder.length && isOrderingLocked):
                return (
                  <div>
                      <p>You can no longer change your order</p>
                      <Button variant='contained' color='primary'>
                          <Link to={`/edit/${todaysOrder[0]._id}`}>
                              View Order
                          </Link>
                      </Button>
                  </div>
                )
            case (todaysOrder.length && !isOrderingLocked):
                return(
                  <>
                      <p>We have received your order but you can still make changes until 11pm</p>
                      <Button variant='contained' color='primary'>
                          <Link to={`/edit/${todaysOrder[0]._id}`}>
                              Edit Order
                          </Link>
                      </Button>
                      {!packagingStarted && <Button variant='outlined' color='secondary' onClick={cancelOrder}>
                          Cancel Order
                      </Button>}
                  </>
                )
            case (!todaysOrder.length && !isOrderingLocked):
                return (
                  <Button variant='contained' color='primary'>
                      <Link to='/order'>
                          Create Order
                      </Link>
                  </Button>
                )
            case (!todaysOrder.length && isOrderingLocked):
            default:
                return <p>Orders can only be placed between 6am - 11pm - please come back later to place your order.</p>
        }
    }

    return (
      <div className="buyer">
          {loading ? <CircularProgress /> :
            <BrowserRouter>
                <Switch>
                    <Route path='/buyer'>
                        <div className="buyer-home">
                            <AppBar position="fixed">
                                <Toolbar variant='dense'>
                                    <div className="header-container">
                                        <Button variant='outlined'
                                                style={{
                                                    color: 'white',
                                                    borderColor: 'white'
                                                }}>
                                            <Link to='/history'>
                                                Orders
                                            </Link>
                                        </Button>
                                        <Button variant='outlined'
                                                onClick={logout}
                                                style={{
                                                    color: 'white',
                                                    borderColor: 'white'
                                                }}>Log out</Button>
                                    </div>
                                </Toolbar>
                            </AppBar>
                            <h2 style={{marginBottom: 20}}>Hello {capitalStart(username)}</h2>
                            <Logo style={{marginBottom: 20}}/>
                            {getButtons()}
                        </div>
                    </Route>

                    <Route path='/order'>
                        <CreateEditOrder
                          onSuccess={getTodaysOrder}
                        />
                    </Route>
                    <Route path='/edit/:orderId'>
                        <CreateEditOrder
                          isEdit={true}
                          onSuccess={getTodaysOrder}
                          isOrderingLocked={isOrderingLocked}
                        />
                    </Route>
                    <Route exact path='/history'>
                        <OrderHistory />
                    </Route>

                    <Route exact path='/order-item/:id/:status/:date'>
                        <OrderItem />
                    </Route>

                    <Redirect to='/buyer'/>

                </Switch>
            </BrowserRouter>}

      </div>
    )
}

export default Buyer
import React from "react";
import {useSnack} from "../../redux/snack/hooks";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const GlobalSnack = () => {
    const { isOpen, message, hideSnack, severity } = useSnack()
    return(
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            open={isOpen}
            autoHideDuration={6000}
            onClose={() => {
                hideSnack()
            }}
        >
            <Alert severity={severity || 'success'}>
                {message}
            </Alert>
        </Snackbar>
    )

}

export default GlobalSnack
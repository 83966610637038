import React, {useState} from 'react'
import { useDispatch } from "react-redux";
import axios from 'axios'
import './styles.scss'
import {LoginSuccessful} from "../../redux/auth/actions";
import { Button, TextField } from '@material-ui/core';
import Box from "@material-ui/core/Box";
import {DIALOGUES} from "../../redux/dialogue/consts";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useDialogue} from "../../redux/dialogue/hooks";

const LoginWidget = ({endpoint}) => {

    const [ username, setUsername ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const { showDialogue } = useDialogue()
    const dispatch = useDispatch()


    const login = async () => {
        if(!username.length) {
            showDialogue(DIALOGUES.SIMPLE, { title: 'please enter username'})
            return
        }
        if(!password){
            showDialogue(DIALOGUES.SIMPLE, { title: 'please enter password'})
            return
        }
        try {
            setLoading(true)
            const response = await axios.post(window.RAYAN_API + endpoint, {
                username,
                password
            })
            dispatch(LoginSuccessful(response.data))

        } catch (e) {
            showDialogue(DIALOGUES.SIMPLE, { title: e.response?.data.message || 'Error'})
        }
        setLoading(false)
    }

    return (
        <div className='login-widget'>
            {loading ? <CircularProgress /> :
                <form>
                    <TextField label="Username"  type="text" value={username} onChange={e => setUsername(e.target.value)}/>
                    <TextField label="Password" type="password" value={password} onChange={e => setPassword(e.target.value)}/>
                    <Box m={2} />
                    <Button className='login-widget__button' variant="contained" color="primary" onClick={login} type='submit'>Login</Button>
                </form>
            }
        </div>
    )
}

export default LoginWidget
import React, {useEffect, useState} from "react";
import axios from "axios";
import DataGrid from "../../componenets/DataGrid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import {useModal} from "../../../../redux/modal/hooks";
import {MODALS} from "../../../../redux/modal/consts";
import {DIALOGUES} from "../../../../redux/dialogue/consts";
import {useDialogue} from "../../../../redux/dialogue/hooks";
import {useSnack} from "../../../../redux/snack/hooks";
import useAuth from "../../../../redux/auth/hooks";
import CircularProgress from "@material-ui/core/CircularProgress";

const Packagers = () => {

  const [ users, setPackagers ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const { showModal } = useModal()
  const { showDialogue } = useDialogue()
  const { showSnack } = useSnack()
  const { isSuperAdmin } = useAuth()

  useEffect( () => {
    getPackagers()
  }, [])

  const getPackagers = async () => {
    try {
      const response = await axios.get(window.RAYAN_API + 'users?roles=packager')
      setPackagers(response.data)
    }catch (e) {
      showSnack(e.response?.data.message, 'error')
    }
    setLoading(false)
  }

  const deleteUser = async packager => {
    try {
      await axios.delete(window.RAYAN_API + 'users?id=' + packager.id)
      showSnack('Packager deleted')
      getPackagers()
    }catch (e) {
      showSnack(e.response?.data.message, 'error')
    }
  }


  const editUser = packager => {
    showModal(MODALS.EDIT_ADD_PACKAGER, {
      isAddMode: false,
      packager,
      onSuccess: () => {
        showSnack(`Packager updated successfully`)
        getPackagers()
      }
    })
  }

  const addUser = () => {
    showModal(MODALS.EDIT_ADD_PACKAGER, {
      isAddMode: true,
      onSuccess: () => {
        showSnack(`Packager added successfully`)
        getPackagers()
      }
    })
  }

  const openDeleteConfirmation = packager => {
    showDialogue(DIALOGUES.CONFIRMATION, {
      onConfirm: () => deleteUser(packager),
      title: `Are you sure you want to delete ${packager.username}`
    })
  }

  if(loading) return <CircularProgress />

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          {isSuperAdmin &&<Button variant='contained' onClick={addUser}>
            Add Packager
          </Button>}
        </Toolbar>
      </AppBar>


      <DataGrid
        headers={[{title: 'Username', key: 'username'}, { title: 'Color', key: 'color', formatter: (col) => <div style={{
            margin: '4px',
            borderRadius: '4px',
            backgroundColor: col,
            width: '30px',
            height: '30px'}}
          />}]}
        data={users}
        isSuperAdmin={isSuperAdmin}
        openEditModal={editUser}
        openDeleteConfirmation={openDeleteConfirmation}
      />
    </div>);
}

export default Packagers;
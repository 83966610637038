import CircularProgress from "@material-ui/core/CircularProgress";
import ProductItem from "../../../../components/ProductItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";
import { useHistory, useParams } from "react-router-dom";
import {useDialogue} from "../../../../redux/dialogue/hooks";
import axios from "axios";
import isEmpty from "lodash.isempty";
import {DIALOGUES} from "../../../../redux/dialogue/consts";
import {useSnack} from "../../../../redux/snack/hooks";
import './styles.scss'
import {MODALS} from "../../../../redux/modal/consts";
import {useModal} from "../../../../redux/modal/hooks";
import {useProducts} from "../../../../redux/products/hooks";

const CreateEditOrder = ({isEdit = false, onSuccess, isOrderingLocked}) => {

    const { orderId } = useParams()
    const [basket, setBasket] = useState({})
    const [previousBasket, setPreviousBasket] = useState({})
    const [loading, setLoading] = useState(true)
    const { showDialogue } = useDialogue()
    const { showModal } = useModal()
    const { products, isLoading: isProductsLoading} = useProducts()
    const history = useHistory()
    const { showSnack } = useSnack()

    useEffect( () => {
        if(isProductsLoading) return
        const init = async () => {
            setLoading(true)

            if (isEdit && !orderId) {
                history.push('/buyer')
            }

            if (isEdit) {
                const response = await axios.get(window.RAYAN_API + `orderitem?orderId=${orderId}`)
                const basket = response.data.reduce((res, item) => {
                    res[item.productId] = item.quantity
                    return res
                }, {})
                setPreviousBasket(basket)
                initBasket(basket)
            }
            setLoading(false)
        }
        init()
    }, [isProductsLoading])

    const initBasket = (basket = {}) => {
        const finalBasket = products.reduce( (result, product) => {
            result[product._id] = basket[product._id] || 0
            return result
        }, {})
        setBasket(finalBasket)
    }


    const checkout = async () => {
        const products = Object.keys(basket)
            .reduce((acc, item) => [...acc, { productId: item, quantity: basket[item] }], [])
        if(isEmpty(products)) return

        if(isEdit){
            const currentBasket = products.reduce( (res, product) => {
                res[product.productId] = product.quantity
                return res
            }, {})
            const currentBasketOrdered = Object.keys(currentBasket).sort().reduce(
              (obj, key) => {
                  if(!currentBasket[key]) return obj
                  obj[key] = currentBasket[key];
                  return obj;
              },
              {}
            );
            const previousBasketOrdered = Object.keys(previousBasket).sort().reduce(
              (obj, key) => {
                  if(!previousBasket[key]) return obj
                  obj[key] = previousBasket[key];
                  return obj;
              },
              {}
            );

            if(JSON.stringify(currentBasketOrdered) === JSON.stringify(previousBasketOrdered)){
                showSnack('No changes made', 'error')
                return
            }
        }

        showModal(MODALS.CONFIRM_ORDER, {
            basket,
            onConfirm: async () => {
                setLoading(true)
                try {
                    if(isEdit){
                        await axios.put(window.RAYAN_API + `orders?id=${orderId}`, {
                            orderItems: products
                        })
                    }else{
                        await axios.post(window.RAYAN_API + 'orders', products)
                    }
                    history.push('/buyer')
                    showSnack('Order placed')
                    onSuccess()
                } catch (e) {
                    showDialogue(DIALOGUES.SIMPLE, { title: e.response?.data.message || 'Error' })
                }
                setLoading(false)
            }
        })


    }

    const updateQuantity = (product, num) => {
        setBasket({
            ...basket,
            [product._id]: num
        })
    }

    const removeQuantity = product => {
        const myBasket = { ...basket }
        myBasket[product._id] = 0
        setBasket(myBasket)
    }


    return(
      <div className='create-edit-order'>
          <AppBar position="fixed">
              <Toolbar variant='dense'>
                  <Button variant='outlined' onClick={history.goBack} className='back-btn'>back</Button>
              </Toolbar>
          </AppBar>
          <div className="product-item-holder">
              {(loading || isProductsLoading) ? <CircularProgress /> :
                products.map( product =>
                  <ProductItem product={product}
                               key={product._id}
                               quantity={basket[product._id]}
                               updateQuantity={updateQuantity}
                               removeQuantity={removeQuantity}
                               isOrderingLocked={isOrderingLocked}
                  />)
              }
          </div>

          {!isOrderingLocked &&
          <AppBar position="fixed" color="primary" style={{
              top: 'auto',
              bottom: 0,
          }}>
              <Toolbar variant='dense' className='footer-toolbar'>
                  <Button variant='outlined'
                          disabled={isEmpty(basket)}
                          className='checkout-btn'
                          onClick={checkout}>
                      {isEdit ? 'Confirm' : 'Checkout'}
                  </Button>
              </Toolbar>
          </AppBar>
          }

      </div>
    )
}

export default CreateEditOrder
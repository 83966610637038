import {ACTIONS} from "./actions";

const initialState = {
    products: [],
    hasLoaded: false
}

const ProductsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_PRODUCTS:
            return {
                products: action.payload.products,
                hasLoaded: true
            }
        default:
            return state
    }
}

export default ProductsReducer
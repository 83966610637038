import React, {useEffect, useState} from 'react';
import moment from 'moment';
import axios from 'axios';
import PackagerGrid from '../../components/PackagerGrid';
import Button from "@material-ui/core/Button";
import {DIALOGUES} from "../../../../redux/dialogue/consts";
import {logout as logoutAction} from "../../../../redux/auth/actions";
import {useDialogue} from "../../../../redux/dialogue/hooks";
import {useDispatch} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useProducts} from "../../../../redux/products/hooks";
import Logo from "../../../../components/Logo";
import { mdiReload } from '@mdi/js';
import Icon from '@mdi/react';
import {useSnack} from "../../../../redux/snack/hooks";
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';

const Dashboard = () => {
    const [ dashboardData, setDashboardData ] = useState([]);
    const {products, isLoading: isLoadingProducts} = useProducts()
    const [loading, setLoading] = useState(true)
    const {showDialogue} = useDialogue();
    const {showSnack} = useSnack();
    const dispatch = useDispatch();

    const getDashboard = async () => {
        try {
            const response = await axios.get(window.RAYAN_API + `picker-dashboard`)
            setDashboardData(response.data)
        }catch (e) {
            console.log('error getting orders', e)
        }
        setLoading(false)
    }

    const logout = () => {
        showDialogue(DIALOGUES.CONFIRMATION, {
            onConfirm: () => {
                dispatch(logoutAction())
            },
            confirmLabel: 'Yes',
            cancelLabel: 'No',
            title: `Logout?`
        })
    }

    useEffect( () => {
        getDashboard()
    }, [])

    if(isLoadingProducts) return <CircularProgress />

    const columns = [{ title: 'Driver', key: 'username'},
        ...products.map(product => ({
            title: product.name,
            key: product._id
        }))]

    return (
      <>
          <AppBar position="static">
              <Toolbar>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%'}}>
                      <Button variant='contained'
                              onClick={async () => {
                                  try {
                                      await getDashboard()
                                      showSnack('Reloaded')
                                  } catch (e) {
                                      showSnack('Error reloading', 'error')
                                  }
                              }}
                              style={{
                                  height: 30,
                              }}
                      >
                          Refresh
                          <Icon path={mdiReload}
                                size={1}
                                color='#3f51b5'
                                style={{cursor: 'pointer'}}
                          />
                      </Button>
                      <p className='order-item__date'>{moment().format('dddd, MMMM D')}</p>
                      <Button variant='contained'
                              onClick={logout}
                              style={{
                                  height: 30,
                              }}
                      >
                          Log out
                      </Button>
                  </div>
              </Toolbar>
          </AppBar>

          <div style={{ padding: '30px'}}>
              {loading
                ? <CircularProgress />
                : <PackagerGrid
                  headers={columns}
                  data={dashboardData}
                  getDashboard={getDashboard}
                />}
          </div>
      </>
    )
}

export default Dashboard;

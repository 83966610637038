import React, {useEffect, useState} from "react";
import axios from "axios";
import DataGrid from "../../componenets/DataGrid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import {useModal} from "../../../../redux/modal/hooks";
import {MODALS} from "../../../../redux/modal/consts";
import {DIALOGUES} from "../../../../redux/dialogue/consts";
import {useDialogue} from "../../../../redux/dialogue/hooks";
import {useSnack} from "../../../../redux/snack/hooks";
import useAuth from "../../../../redux/auth/hooks";
import CircularProgress from "@material-ui/core/CircularProgress";

const Admins = () => {

    const [ admins, setAdmins ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const { showModal } = useModal()
    const { showDialogue } = useDialogue()
    const { showSnack } = useSnack()
    const { isSuperAdmin } = useAuth()

    const getAdmins = async () => {
        try {
            const response = await axios.get(window.RAYAN_API + 'users?roles=admin')
            setAdmins(response.data)
        }catch (e) {
            console.log('error getting admins', e)
        }
        setLoading(false)
    }
    useEffect( () => {
        getAdmins()
    }, [])

    const openDeleteConfirmation = admin => {
        showDialogue(DIALOGUES.CONFIRMATION, {
            onConfirm: () => deleteAdmin(admin),
            title: `Are you sure you want to delete ${admin.username}`
        })
    }



    const editAdmin = admin => {
        showModal(MODALS.EDIT_ADD_ADMIN, {
            isAddMode: false,
            admin,
            onSuccess:() => {
                showSnack(`Admin updated successfully`)
                getAdmins()
            }
        })
    }

    const addAdmin = () => {
        showModal(MODALS.EDIT_ADD_ADMIN, {
            isAddMode: true,
            onSuccess:() => {
                showSnack(`Admin added successfully`)
                getAdmins()
            }
        })
    }

    const deleteAdmin = async admin => {
        try {
            await axios.delete(window.RAYAN_API + 'users?id=' + admin.id)
            getAdmins()
            showSnack('Operator deleted')
        }catch (e) {
            console.log('error deleting admin')
        }
    }

    if(loading) return <CircularProgress />


    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    {isSuperAdmin &&<Button variant='contained' onClick={addAdmin}>
                        Add Admin
                    </Button>}
                </Toolbar>
            </AppBar>
            <DataGrid headers={[{title: 'Username', key: 'username'}]}
                      data={admins}
                      isSuperAdmin={isSuperAdmin}
                      openEditModal={editAdmin}
                      openDeleteConfirmation={openDeleteConfirmation}
            />
        </div>)
}

export default Admins;
import React, {useEffect, useState} from "react";
import Modal from '@material-ui/core/Modal';
import './styles.scss';
import {Button, TextField} from "@material-ui/core";
import axios from "axios";
import {useModal} from "../../../../redux/modal/hooks";
import {useDialogue} from "../../../../redux/dialogue/hooks";
import {DIALOGUES} from "../../../../redux/dialogue/consts";
import CircularProgress from "@material-ui/core/CircularProgress";

const EditAddOperatorModal = ({isAddMode, operator, onSuccess}) => {

    const [username, setUsername ] = useState()
    const [password, setPassword ] = useState()
    const [loading, setLoading] = useState(false)
    const { hideModal } = useModal()
    const { showDialogue } = useDialogue()


    useEffect( () => {
        if(!isAddMode) {
            setUsername(operator.username)
            setPassword('')
        }
    }, [operator])

    const shouldDisableSaveBtn = () => {
        if(password) return false
        if(username === operator?.username) return true
    }

    const addOperator = async () => {
        await axios.post(window.RAYAN_API + 'users', {
            username,
            password,
            roles:['operator']
        })
    }

    const editOperator = async () => {
        await axios.put(window.RAYAN_API + 'users?id=' + operator.id, JSON.parse(JSON.stringify({
            username: username === operator.username ? undefined : username,
            password: password || undefined
        })))
    }

    const editAddOperator = async () => {
        setLoading(true)
        try {
            if (isAddMode) {
                await addOperator()
            } else {
                await editOperator()
            }
            hideModal()
            onSuccess()
        }catch (e) {
            showDialogue(DIALOGUES.SIMPLE, {
                title: e.response?.data.message || 'Error'
            })
        }
        setLoading(false)

    }

    return(
        <Modal onBackdropClick={hideModal} open={true} className='edit-operator-modal'>
            <div className='edit-operator-modal__content'>
                {loading ? <CircularProgress /> :
                    <>
                        <h2 id="modal-title">
                            {isAddMode ? 'Add' : 'Edit'} Operator
                        </h2>
                        <p id="modal-description">
                            Enter new details below and then click save.
                        </p>

                        <TextField label="Username"  type="text" value={username} onChange={e => setUsername(e.target.value)}/>
                        <TextField label="Password"  type="text" value={password} onChange={e => setPassword(e.target.value)}/>

                        <div className='edit-operator-modal__action-bar'>
                            <Button style={{marginRight: 10}} variant="outlined" color="secondary" onClick={hideModal}>Cancel</Button>
                            <Button variant="contained" color="primary" disabled={shouldDisableSaveBtn()} onClick={editAddOperator}>{isAddMode ? 'Add' : 'Save'}</Button>
                        </div>
                    </>}
            </div>
        </Modal>
    )
}

export default EditAddOperatorModal
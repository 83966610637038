import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import {Button} from "@material-ui/core";
import {useDialogue} from "../../redux/dialogue/hooks";

function SimpleDialog(props) {
    const { title } = props;
    const { hideDialogue } = useDialogue()

    return (
        <Dialog onClose={hideDialogue} aria-labelledby="simple-dialog-title" open={true}>
            <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
            <Button variant="text" color="primary" fullWidth={false} onClick={hideDialogue}>Ok</Button>
        </Dialog>
    );
}

export default SimpleDialog
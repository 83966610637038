import React from "react";
import SuperAdmin from "./SuperAdmin";
import Operator from "./Operator";
import Packager from "./Packager";
import Buyer from "./Buyer";

const getApp = (roles) => {
    if(roles.includes('ROLE_SUPERADMIN') || roles.includes('ROLE_ADMIN')){
        return <SuperAdmin />
    }
    if(roles.includes('ROLE_OPERATOR')){
        return <Operator/>
    }
    if(roles.includes('ROLE_USER')){
        return <Buyer />
    }
    if(roles.includes('ROLE_PACKAGER')){
        return <Packager />
    }
}

export default getApp
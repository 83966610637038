import {useDispatch, useSelector} from "react-redux";
import {showDialogue as showDialogueAction, hideDialogue as hideDialogueAction} from "./actions";

export const useDialogue = () => {
    const {name, props, isOpen} = useSelector(state => state.dialogue);
    const dispatch = useDispatch()

    const showDialogue = (name, props) => {
        dispatch(showDialogueAction(name, props))
    }
    const hideDialogue = () => {
        dispatch(hideDialogueAction())
    }

    return {
        showDialogue,
        hideDialogue,
        isOpen,
        name,
        props
    }
}
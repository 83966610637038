import {ACTIONS} from "./actions";

const initialState = {
    isOpen: false,
    message: '',
    severity: ''
}

const SnackReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SHOW_SNACK:
            return {
                isOpen: true,
                message: action.payload.message,
                severity: action.payload.severity
            }
        case ACTIONS.HIDE_SNACK:
            return {
                isOpen: false,
                message: '',
                severity: ''
            }
        default:
            return state
    }
}

export default SnackReducer
export const ACTIONS = {
    SHOW_SNACK:'SHOW_SNACK',
    HIDE_SNACK:'HIDE_SNACK'
}

export const showSnack = (message, severity = '') => {
    return {
        type: ACTIONS.SHOW_SNACK,
        payload:{
            message,
            severity
        }
    }
}
export const hideSnack = () => {
    return {
        type: ACTIONS.HIDE_SNACK
    }
}
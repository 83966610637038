import React from "react";
import './styles.scss';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import DailyOrders from './views/dailyOrders';
import Order from './views/order';
import Dashboard from './views/dashboard';

const Operator = () =>(
    <div className="operator">
      <Router>
        <Switch>
          <Route exact path='/operator'>
            <DailyOrders/>
          </Route>
          <Route exact path='/order-item/:id/:status/:name'>
            <Order />
          </Route>
          <Route exact path='/tv'>
            <Dashboard />
          </Route>
          <Redirect to='/operator'/>
        </Switch>
      </Router>
    </div>
)


export default Operator